import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  pdflist: null, // Add new state
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }

    case 'SET_PDFLIST': // Add a new action type
      return { ...state, pdflist: rest.pdflist }; // Update newState
    default:
      return state
  }
}

const store = createStore(changeState)
export default store



// import { createStore } from 'redux';

// const initialState = {
//   sidebarShow: true,
//   sidebarUnfoldable: true,
//   chartTablelist: [],
// };

// const changeState = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, sidebarShow:payload };

//       case 'set2':
//         return { ...state, sidebarUnfoldable:payload };

//     case 'UPDATE_chartlist':
//       return {
//         ...state,
//         chartTablelist: payload,
//       };

//     default:
//       return state;
//   }
// };

// const store = createStore(changeState);
// export default store;
